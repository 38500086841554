import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './audience.module.scss';
import SideImage from '../../assets/images/Group.png';
import FillDots from '../../assets/icons/fillDots';
import GreenDots from '../../assets/icons/greenDots';
// import Tick from '../../assets/icons/tick';

const Audience = () => {
  return (
    <Container className={`${styles.wrapper} py-5`}>
      <Row>
        <Col className='ps-xl-5 pt-xl-5 ps-lg-5 pt-lg-5 ps-md-5 ps-4' data-aos="zoom-in-right" data-aos-duration="600">
          <Row data-aos="fade-up" data-aos-duration="500">
            <p className={`${styles.fontColor} h5`}>Target Audience </p>
          </Row>
          <p className='h3' data-aos="fade-up" data-aos-duration="550">Who We Serve</p>
          <p className='fw-medium lh-lg w-xl-75' data-aos="fade-up" data-aos-duration="600">Unlock the power of visual storytelling with our comprehensive suite of video creation tools. From captivating explainer videos to insightful tutorials, educational content, immersive online courses, and engaging product reviews, we empower businesses and individuals to craft compelling video narratives that resonate with their audiences. Elevate your brand, share your knowledge, and captivate your viewers with our user-friendly platform tailored to your unique content needs.</p>
          {/* <p className='h5 mt-3'>
            <Tick/>
            <span className='ms-3'>Comprehensive video creation tools</span>
          </p>
          
          <p className='h5 mt-3'>
            <Tick/>
            <span className='ms-3'>Create captivating explainer videos</span>
          </p>
          <p className='h5 mt-3'>
            <Tick/>
            <span className='ms-3'>Create insightful tutorials and educational content</span>
          </p>
          <p className='h5 mt-3'>
            <Tick/>
            <span className='ms-3'>Develop immersive online courses and engaging product reviews</span>
          </p>
          <p className='h5 mt-3'>
            <Tick/>
            <span className='ms-3'>Empower your brand and share knowledge</span>
          </p>
          <p className='h5 mt-3'>
            <Tick/>
            <span className='ms-3'>User-friendly platform tailored to your content needs</span>
          </p> */}
        </Col>
        <Col xl="6">
          <div className={styles.sideImage} data-aos="zoom-in" data-aos-duration="500">
            <span className={styles.fillDots}>
              <FillDots color={"#FDBA09"}/>
            </span>
            <span className={styles.greenDots}>
              <GreenDots color={"#3E66F3"}/>
            </span>
            <span className={styles.borderBg}></span>
            <img src={SideImage} alt='img' />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Audience;
