import React from 'react';

const Points = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 1.28571C4.73979 1.28571 1.28571 4.73979 1.28571 9C1.28571 13.2602 4.73979 16.7143 9 16.7143C13.2602 16.7143 16.7143 13.2602 16.7143 9C16.7143 4.73979 13.2602 1.28571 9 1.28571ZM0 9C0 4.02943 4.02943 0 9 0C13.9706 0 18 4.02943 18 9C18 13.9706 13.9706 18 9 18C4.02943 18 0 13.9706 0 9ZM5.14286 6.42857C5.14286 6.08758 5.27832 5.76055 5.51943 5.51943C5.76055 5.27832 6.08758 5.14286 6.42857 5.14286H11.5714C11.9124 5.14286 12.2394 5.27832 12.4806 5.51943C12.7217 5.76055 12.8571 6.08758 12.8571 6.42857V11.5714C12.8571 11.9124 12.7217 12.2394 12.4806 12.4806C12.2394 12.7217 11.9124 12.8571 11.5714 12.8571H6.42857C6.08758 12.8571 5.76055 12.7217 5.51943 12.4806C5.27832 12.2394 5.14286 11.9124 5.14286 11.5714V6.42857Z" fill="#F78900"/>
    </svg>

  );
};

export default Points;
