import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './challenges.module.scss';
import Points from '../../assets/icons/points';
import SideImage from '../../assets/images/image 5.png';
import FillDots from '../../assets/icons/fillDots';
import GreenDots from '../../assets/icons/greenDots';
import Lineone from '../../assets/icons/lineone';

const Challenges = () => {
  return (
    <Container className={`${styles.wrapper} py-5`}>
      <Row>
        <Col xl="6" data-aos="fade-up" data-aos-duration="500">
          <div className={styles.sideImage}>
            <span className={styles.fillDots}>
              <FillDots color={"#473BF0"}/>
            </span>
            <span className={styles.greenDots}>
              <GreenDots color={"#68D585"}/>
            </span>
            <span className={styles.line}>
              <Lineone />
            </span>
            <span className={styles.borderBg}></span>
            <img src={SideImage} alt='img' />
          </div>
        </Col>
        <Col className='ps-md-5 pt-md-5'>
          <Row data-aos="fade-up" data-aos-duration="500">
            <p className={`${styles.fontColor} h5`}>Challenges</p>
          </Row>
          <Row data-aos="fade-up" data-aos-duration="550">
            <p className='h3'>Video Content Creation</p>
          </Row>
          <Row data-aos="fade-up" data-aos-duration="600">
            <p className='fw-medium lh-lg'>Creating video content involves meticulous planning for layout and scripting, alongside filming, audio recording, and editing. Accuracy and privacy are crucial for impactful, engaging videos.</p>
          </Row>
          <Row className='overflow-hidden' data-aos="fade-up" data-aos-duration="600">
            <p className='h5 mt-3'>
              <Points/>
              <span className='ms-3'>Time Consuming process</span>
            </p>
            <div className='ms-4 mt-3'>
              <ul>
                <li className='fw-medium'>Structure/Layout creation</li>
                <li className='fw-medium'>Script writing</li>
                <li className='fw-medium'>Video generation: shooting, audio dubbing, editing</li>
              </ul>
              
            </div>
            <p className='h5 mt-3' data-aos="fade-up" data-aos-duration="600">
              <Points/>
              <span className='ms-3'>Accuracy of content</span>
            </p>
            <p className='h5 mt-3' data-aos="fade-up" data-aos-duration="600">
              <Points/>
              <span className='ms-3'>Privacy of video content</span>
            </p>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Challenges;
