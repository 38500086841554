import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar, Button } from 'react-bootstrap';
import Footer from "../footer/index";
import ScrollIndicator from '../scrollIndicator';
import AppLogo from '../../assets/icons/applogo';
import styles from '../page.module.scss';

const MainLayout = ({ children }) => {
  const [progressWidth, setProgressWidth] = useState();

  useEffect(() => {
    const handleScroll = () => {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrolled = (winScroll / height) * 100;
      setProgressWidth(scrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div>
      {/* Scroll Indicator */}
      <div className={`${progressWidth > 2 && "bg-body-tertiary"} position-sticky top-0 z-3`}>
        <ScrollIndicator progressWidth={progressWidth} />
        
        {/* Navbar */}
        <Navbar expand="lg" className={`${styles.navText} z-3 position-sticky top-0`}>
          <Container>
            <Navbar.Brand href="https://feedio.ai/" className={`${styles.navText} ${styles.appLogo}`}>
              <AppLogo height={40} width={250} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" className="ms-auto" />
            <Navbar.Collapse id="navbarScroll">
              <Nav className="ms-auto my-2 my-lg-0 mx-auto gap-3" style={{ maxHeight: '270px' }}>
                <Nav.Link href="https://feedio.ai/" className="fw-semibold">Home</Nav.Link>
                {/* <Nav.Link href="https://feedio.ai/" className="fw-semibold">About us</Nav.Link>
                <Nav.Link href="https://feedio.ai/" className="fw-semibold">Services</Nav.Link> */}
                <Nav.Link href="https://feedio.ai/" className="fw-semibold">Use Cases</Nav.Link>
                <Nav.Link href="https://feedio.ai/" className="fw-semibold">Contact us</Nav.Link>
                {/* <Nav.Link href="https://app.feedio.ai/" className="fw-semibold">Login</Nav.Link> */}
              </Nav>
              <Button href='https://app.feedio.ai/' className={`shadow-1 ${styles.cusbtn}`}>Start Free Trial</Button>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

      {/* Main Content */}
      <Container className="pt-3">
        <main>{children}</main>
      </Container>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default MainLayout;
